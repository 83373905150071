'use client';
import { Dialog, DialogContent, DialogHeader, DialogTitle } from '@/components/atom/dialog';
import React from 'react';
import SignInForm from './SignInForm';
import { useRouter, useSearchParams, usePathname } from 'next/navigation';

const SignInModal = () => {
  const router = useRouter();
  const params = useSearchParams();
  const pathname = usePathname();

  return (
    <Dialog open={params.get('ref') === 'signin'} onOpenChange={() => router.replace(pathname)}>
      <DialogContent className="w-screen-safe h-screen-safe md:h-fit rounded-none md:rounded-lg md:w-full md:max-w-[480px] p-4 md:p-10 antialiased flex flex-col">
        <DialogHeader className="w-full max-w-[320px] mx-auto mt-10 text-start">
          <DialogTitle className="relative font-medium text-2xl text-purple-brand3">
            <span className="sr-only">Sign in Modal</span>
          </DialogTitle>
        </DialogHeader>
        <SignInForm
          className="shadow-none"
          onSigninComplete={(v) => router.replace(`${pathname}${v}`)}
        />
      </DialogContent>
    </Dialog>
  );
};

export default SignInModal;
