export const BulkShoppingCart = () => (
  <svg width="64" height="64" viewBox="0 0 64 64" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M43.3346 60.0013C45.912 60.0013 48.0013 57.912 48.0013 55.3346C48.0013 52.7573 45.912 50.668 43.3346 50.668C40.7573 50.668 38.668 52.7573 38.668 55.3346C38.668 57.912 40.7573 60.0013 43.3346 60.0013Z"
      fill="#FF951C"
    />
    <path
      d="M21.9987 60.0013C24.576 60.0013 26.6654 57.912 26.6654 55.3346C26.6654 52.7573 24.576 50.668 21.9987 50.668C19.4214 50.668 17.332 52.7573 17.332 55.3346C17.332 57.912 19.4214 60.0013 21.9987 60.0013Z"
      fill="#FF951C"
    />
    <path
      opacity="0.4"
      d="M12.9054 10.5073L12.372 17.0406C12.2654 18.294 13.252 19.334 14.5054 19.334H55.332C56.452 19.334 57.3854 18.4806 57.4654 17.3606C57.812 12.6406 54.212 8.80066 49.492 8.80066H16.772C16.5053 7.62733 15.972 6.50731 15.1453 5.57397C13.8387 4.16064 11.972 3.33398 10.052 3.33398H5.33203C4.2387 3.33398 3.33203 4.24065 3.33203 5.33398C3.33203 6.42732 4.2387 7.33398 5.33203 7.33398H9.97205C10.7987 7.33398 11.572 7.68067 12.132 8.26733C12.692 8.88067 12.9587 9.68066 12.9054 10.5073Z"
      fill="#601E8A"
    />
    <path
      d="M54.6948 23.334H13.7881C12.6681 23.334 11.7614 24.1873 11.6548 25.2806L10.6948 36.8807C10.3214 41.414 13.8948 45.334 18.4548 45.334H48.1081C52.1081 45.334 55.6281 42.054 55.9215 38.054L56.8015 25.6007C56.9081 24.374 55.9481 23.334 54.6948 23.334Z"
      fill="#601E8A"
    />
  </svg>
);
