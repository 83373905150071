export const ImageGalleryAdd = () => (
  <svg width="32" height="28" viewBox="0 0 32 28" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M27.9597 1.33301H24.0397C22.8797 1.33301 22.0263 1.81301 21.6397 2.66634C21.4263 3.05301 21.333 3.50634 21.333 4.03967V7.95967C21.333 9.65301 22.3463 10.6663 24.0397 10.6663H27.9597C28.493 10.6663 28.9463 10.573 29.333 10.3597C30.1863 9.97301 30.6663 9.11967 30.6663 7.95967V4.03967C30.6663 2.34634 29.653 1.33301 27.9597 1.33301ZM29.213 6.57301C29.0797 6.70634 28.8797 6.79967 28.6663 6.81301H26.7863V7.49301L26.7997 8.66634C26.7863 8.89301 26.7063 9.07967 26.5463 9.23967C26.413 9.37301 26.213 9.46634 25.9997 9.46634C25.5597 9.46634 25.1997 9.10634 25.1997 8.66634V6.79967L23.333 6.81301C22.893 6.81301 22.533 6.43967 22.533 5.99967C22.533 5.55967 22.893 5.19967 23.333 5.19967L24.5063 5.21301H25.1997V3.34634C25.1997 2.90634 25.5597 2.53301 25.9997 2.53301C26.4397 2.53301 26.7997 2.90634 26.7997 3.34634L26.7863 4.29301V5.19967H28.6663C29.1063 5.19967 29.4663 5.55967 29.4663 5.99967C29.453 6.22634 29.3597 6.41301 29.213 6.57301Z"
      fill="#9B8FA1"
    />
    <path
      d="M11.9995 13.8398C13.7521 13.8398 15.1728 12.4191 15.1728 10.6665C15.1728 8.91391 13.7521 7.49316 11.9995 7.49316C10.2469 7.49316 8.82617 8.91391 8.82617 10.6665C8.82617 12.4191 10.2469 13.8398 11.9995 13.8398Z"
      fill="#9B8FA1"
    />
    <path
      d="M27.9603 10.667H27.3337V16.8137L27.1603 16.667C26.1203 15.7737 24.4403 15.7737 23.4003 16.667L17.8537 21.427C16.8137 22.3203 15.1337 22.3203 14.0937 21.427L13.6403 21.0537C12.6937 20.227 11.187 20.147 10.1203 20.867L5.13366 24.2137C4.84033 23.467 4.66699 22.6003 4.66699 21.587V10.4137C4.66699 6.65366 6.65366 4.66699 10.4137 4.66699H21.3337V4.04033C21.3337 3.50699 21.427 3.05366 21.6403 2.66699H10.4137C5.56033 2.66699 2.66699 5.56033 2.66699 10.4137V21.587C2.66699 23.0403 2.92033 24.307 3.41366 25.3737C4.56033 27.907 7.01366 29.3337 10.4137 29.3337H21.587C26.4403 29.3337 29.3337 26.4403 29.3337 21.587V10.3603C28.947 10.5737 28.4937 10.667 27.9603 10.667Z"
      fill="#9B8FA1"
    />
  </svg>
);
