import React, { ChangeEvent, forwardRef, useCallback, useRef, useState } from 'react';
import { Button } from '../atom/button';
import { convertToBase64 } from '@/lib/utils';
import { MediaType } from '@/types';

interface UploadNINProps {
  onFileSelected: (v: MediaType) => void;
}

const UploadNIN = forwardRef<HTMLButtonElement, UploadNINProps>(({ onFileSelected }, ref) => {
  const inputRef = useRef<HTMLInputElement | null>(null);
  const [file, setFile] = useState<File | null>(null);
  const handleUpload = useCallback(() => {
    if (inputRef.current) {
      if (inputRef.current.files?.[0]) {
        inputRef.current.files = null;
        setFile(null);
      }
      inputRef.current.click();
    }
  }, []);
  const handleInputChange = useCallback(async (e: ChangeEvent<HTMLInputElement>) => {
    if (inputRef.current) {
      setFile(inputRef.current.files?.[0] ? inputRef.current.files?.[0] : null);
      const file = e.target.files?.[0] as File;
      if (file) {
        const base64 = await convertToBase64(file);
        onFileSelected(base64);
      }
    }
  }, []);

  const handleReset = () => {
    if (inputRef.current) {
      inputRef.current.files = null;
      inputRef.current.value = '';
    }
    setFile(null);
  };

  return (
    <div>
      {file ? (
        <div className="flex items-center justify-between w-full gap-1">
          <svg
            width="20"
            height="20"
            viewBox="0 0 20 20"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            className="mr-2"
          >
            <path
              d="M10.2754 10.1259L8.21706 12.1842C7.07539 13.3259 7.07539 15.1676 8.21706 16.3092C9.35872 17.4509 11.2004 17.4509 12.3421 16.3092L15.5837 13.0676C17.8587 10.7926 17.8587 7.09258 15.5837 4.81758C13.3087 2.54258 9.60873 2.54258 7.33372 4.81758L3.80039 8.35091C1.85039 10.3009 1.85039 13.4676 3.80039 15.4259"
              stroke="#601E8A"
              strokeWidth="1.5"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
          </svg>
          <span className="flex-1 text-nowrap text-ellipsis text-purple-brand4 w-1/2 overflow-hidden">
            {file?.name}
          </span>

          <Button
            type="button"
            onClick={handleUpload}
            variant="outline"
            className="text-purple-brand h-9"
          >
            Change
          </Button>
        </div>
      ) : (
        <Button
          type="button"
          onClick={handleUpload}
          variant="outline"
          className="text-purple-brand"
        >
          <svg
            width="20"
            height="20"
            viewBox="0 0 20 20"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            className="mr-2"
          >
            <path
              d="M10.2754 10.1259L8.21706 12.1842C7.07539 13.3259 7.07539 15.1676 8.21706 16.3092C9.35872 17.4509 11.2004 17.4509 12.3421 16.3092L15.5837 13.0676C17.8587 10.7926 17.8587 7.09258 15.5837 4.81758C13.3087 2.54258 9.60873 2.54258 7.33372 4.81758L3.80039 8.35091C1.85039 10.3009 1.85039 13.4676 3.80039 15.4259"
              stroke="#601E8A"
              strokeWidth="1.5"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
          </svg>
          Upload NIN ID
        </Button>
      )}

      <input
        className="hidden"
        ref={inputRef}
        type="file"
        onChange={handleInputChange}
        accept="image/*,.pdf"
      />
      <button ref={ref} onClick={handleReset} />
    </div>
  );
});

export default UploadNIN;
