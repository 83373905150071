'use client';

import { EditorContent } from '@tiptap/react';
import React from 'react';
import { useSession } from 'next-auth/react';
import Avatar from '@/components/atom/avatar';
import { Button } from '@/components/atom/button';
import { ClockIcon } from '@/components/ui/icons/clock-icon';
import { EmojiHappy } from '@/components/ui/icons/emoji-happy';
import ImageGallery from '@/components/ui/icons/image-gallery';
import { VideoPlay } from '@/components/ui/icons/video-play';
import SelectCommunityModal from './SelectCommunityModal';
import { CreatePostPayloadType, MediaType } from '@/types';
import useCreatePostMutation from '@/hooks/react-query/posts/mutations/useCreatePostMutation';
import { useToast } from '@/hooks/use-toast';
import { usePathname, useRouter, useSearchParams } from 'next/navigation';
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuTrigger,
} from '@/components/atom/dropdown-menu';
import data from '@emoji-mart/data';
import Picker from '@emoji-mart/react';
import MultipleImageUploader from './MultipleImagesUploader';
import MetadataPreviewLoader from '@/components/metadata-preview-loader';
import useLoadPreview from '@/hooks/use-load-preview';
import MetadataPreview from '@/components/metadata-preview';
import useTipTapEditor from '@/hooks/use-tiptap-editor';
import { useQueryClient } from '@tanstack/react-query';
import { Loader2Icon } from 'lucide-react';
import useVideoThumbnail from '@/hooks/use-video-thumbnail';
import { PostCallEnum } from '@/utils/enums';
const PostEditor = ({ className, errorMessage }: { className?: string; errorMessage?: string }) => {
  const searchParams = useSearchParams();
  const { generateThumbnailsFromFiles } = useVideoThumbnail({});
  const communityIdParam = searchParams.get('communityId');
  const queryClient = useQueryClient();
  const imgInputRef = React.useRef<HTMLInputElement | null>(null);
  const videoInputRef = React.useRef<HTMLInputElement | null>(null);
  const pathname = usePathname();
  const router = useRouter();
  const { toast } = useToast();
  const [open, setOpen] = React.useState(false);
  const [payload, setPayload] = React.useState<Partial<CreatePostPayloadType>>({});
  const session = useSession();
  const createPostMutation = useCreatePostMutation();
  const { editor, value } = useTipTapEditor({
    placeholder: 'Start typing',
    className,
    errorMessage,
  });

  const { loading, metadata, handleClose } = useLoadPreview(value);

  const onNext = async () => {
    if (communityIdParam) {
      const data: CreatePostPayloadType = {
        ...payload,
        communityId: Number(communityIdParam),
        title: '',
        tags: [],
        taggedUsers: [],
        checking: true,
        allowComments: true,
        allowLikes: true,
        allowShares: true,
        content: value,
      };
      submitData(data);
    } else {
      setOpen(true);
    }
  };

  const handleSubmit = async (communityId: number) => {
    const data: CreatePostPayloadType = {
      ...payload,
      communityId,
      title: '',
      tags: [],
      taggedUsers: [],
      checking: true,
      allowComments: true,
      allowLikes: true,
      allowShares: true,
      content: value,
    };
    submitData(data);
  };

  const submitData = async (data: CreatePostPayloadType) => {
    const videos = data?.media?.filter((med) => med.contentType.includes('video')) ?? [];
    const videoFiles = videos.map((v) => v.file);
    const videoThumbnailImages = await generateThumbnailsFromFiles(videoFiles as File[]);
    const videoThumbnails: MediaType[] = videoFiles.map((v, idx) => ({
      name: (v?.name?.split('.')[0] ?? '') + '.jpeg',
      base64: videoThumbnailImages[idx].split(',').pop() ?? '',
      contentType: 'image/jpeg',
    }));
    const media: MediaType[] =
      data?.media?.map((med) => ({
        base64: med.base64,
        contentType: med.contentType,
        name: med.name,
      })) ?? [];
    createPostMutation.mutate(
      { ...data, thumbNail: videoThumbnails, media },
      {
        onSuccess: (response) => {
          toast({
            description: response.message ?? 'Post created successfully',
            variant: 'success',
          });
          editor?.commands.clearContent();
          setPayload({});
          if (imgInputRef.current) {
            imgInputRef.current.value = ''; // Reset the file input
            imgInputRef.current.files = null; // Reset the file input
          }
          queryClient.invalidateQueries({
            queryKey: [PostCallEnum.Feeds],
          });
          queryClient.invalidateQueries({
            queryKey: ['GetPopularPosts'],
          });
          queryClient.invalidateQueries({
            queryKey: ['GetAllPosts'],
          });
          router.replace(pathname);
        },
        onError: (err: unknown) => {
          const error = err as unknown as string;
          toast({
            variant: 'destructive',
            description: error,
          });
        },
      }
    );
  };

  return (
    <div className="flex flex-col h-full justify-between gap-6 w-full">
      <div className="flex flex-col gap-4 p-6 w-full bg-pink-brand rounded-lg max-h-[calc(100vh-200px)] md:max-h-[400px] overflow-y-auto flex-1">
        <div className="flex w-fit gap-2.5 font-semibold">
          <Avatar
            fallbackText={session?.data?.user?.name ?? ''}
            className="h-8 w-8"
            src={(session?.data?.user?.avatarUrl || session?.data?.user?.image) ?? ''}
            isOnline
          />
          <span className="md:flex items-center justify-between gap-2.5 hidden">
            {session?.data?.user?.username}
          </span>
        </div>

        {loading ? (
          <MetadataPreviewLoader />
        ) : (
          metadata && <MetadataPreview data={metadata} handleClose={handleClose} />
        )}

        <div>
          <EditorContent editor={editor} />
        </div>

        <MultipleImageUploader
          ref={imgInputRef}
          onImageSelected={(v) => {
            setPayload((prev) => ({ ...prev, media: v }));
          }}
        />
        {/* <MultipleVideosUploader
          ref={videoInputRef}
          onImageSelected={(v) =>
            setPayload((prev) => ({ ...prev, media: prev.media ? [...prev.media, ...v] : v }))
          }
        /> */}
      </div>
      <div className="flex items-center justify-between">
        <div className="flex items-center gap-1">
          <DropdownMenu>
            <DropdownMenuTrigger asChild>
              <Button variant="ghost" size="icon" className="p-0 rounded-full">
                <EmojiHappy />
              </Button>
            </DropdownMenuTrigger>
            <DropdownMenuContent className="p-0 border-none shadow-none" align="start">
              <DropdownMenuItem>
                <Picker
                  data={data}
                  onEmojiSelect={(emoji: { native: string }) =>
                    editor?.commands.insertContent(emoji.native)
                  }
                  emojiSize={16}
                  theme="light"
                />
              </DropdownMenuItem>
            </DropdownMenuContent>
          </DropdownMenu>

          <Button
            variant="ghost"
            size="icon"
            className="p-0 rounded-full"
            onClick={() => imgInputRef!.current?.click()}
          >
            <ImageGallery />
          </Button>
          <Button
            variant="ghost"
            size="icon"
            className="p-0 rounded-full"
            onClick={() => videoInputRef!.current?.click()}
          >
            <VideoPlay />
          </Button>
          <Button variant="ghost" size="icon" className="p-0 rounded-full">
            <ClockIcon />
          </Button>
        </div>
        <Button disabled={!value.trim()} onClick={onNext}>
          {communityIdParam ? (
            createPostMutation.isPending ? (
              <>
                <Loader2Icon size={16} className="animate-spin mr-2" /> Processing...
              </>
            ) : (
              'Create'
            )
          ) : (
            'Next'
          )}
        </Button>
      </div>

      <SelectCommunityModal
        open={open}
        onOpenChange={() => setOpen(!open)}
        onFinishPost={handleSubmit}
        loading={createPostMutation.isPending}
      />
    </div>
  );
};

export default PostEditor;
