import React from 'react';
import {
  DialogDescription,
  DialogFooter,
  DialogHeader,
  DialogTitle,
} from '@/components/atom/dialog';
import {
  InputOTP,
  InputOTPGroup,
  InputOTPSeparator,
  InputOTPSlot,
} from '@/components/atom/otp-input';
import Countdown from '@/components/atom/Countdown';

const ValidateEmailForm = ({
  email,
  onSubmit,
  onChangeEmail,
  onResend,
}: {
  email: string;
  onSubmit: (otp: string) => void;
  onChangeEmail: () => void;
  onResend: () => void;
}) => {
  const [value, setValue] = React.useState('');
  const [expired, setExpired] = React.useState(false);

  const obscureEmail = (email: string): string => {
    if (!email) return '';
    const [localPart, domain] = email.split('@');

    if (localPart.length < 3) {
      return email;
    }
    const firstChar = localPart[0];
    const lastChar = localPart[localPart.length - 1];

    const obscuredPart = '*'.repeat(localPart.length - 2);

    const obscuredEmail = `${firstChar}${obscuredPart}${lastChar}@${domain}`;
    return obscuredEmail;
  };

  const handleSubmit = () => {
    if (value.length === 6) {
      onSubmit(value);
    }
  };

  React.useEffect(() => {
    handleSubmit();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [value]);

  return (
    <>
      <DialogHeader className="w-full max-w-[412px] mx-auto">
        <DialogTitle className="mt-10 mb-1 text-2xl font-medium text-purple-brand3 lg:mt-20">
          Validate email address
        </DialogTitle>
        <DialogDescription className="text-sm text-gray-brand6">
          A verification code has been sent to {obscureEmail(email)}
          <br />
          <button onClick={onChangeEmail} className="text-sm font-medium text-orange-brand ring-0">
            Change email
          </button>
        </DialogDescription>
      </DialogHeader>
      <div className="w-full max-w-[412px] mx-auto mb-8 lg:mb-10">
        <p className="text-sm font-medium text-gray-brand6">Enter Code</p>
        <InputOTP maxLength={6} value={value} onChange={(value) => setValue(value)}>
          <InputOTPGroup>
            <InputOTPSlot index={0} placeholder="0" />
            <InputOTPSlot index={1} placeholder="0" />
            <InputOTPSlot index={2} placeholder="0" />
          </InputOTPGroup>
          <InputOTPSeparator />
          <InputOTPGroup>
            <InputOTPSlot index={3} placeholder="0" />
            <InputOTPSlot index={4} placeholder="0" />
            <InputOTPSlot index={5} placeholder="0" />
          </InputOTPGroup>
        </InputOTP>
      </div>
      <DialogFooter className="mb-10 text-center sm:justify-center lg:mb-20">
        {expired ? (
          <button
            type="button"
            className="text-sm font-medium text-orange-brand ring-0"
            onClick={onResend}
          >
            Resend OTP
          </button>
        ) : (
          <Countdown onTimeout={() => setExpired(true)} />
        )}
      </DialogFooter>
    </>
  );
};

export default ValidateEmailForm;
