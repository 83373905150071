export const LinearLayer = () => (
  <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M8.67367 1.94688L12.607 3.69354C13.7403 4.19354 13.7403 5.02021 12.607 5.52021L8.67367 7.26688C8.22701 7.46688 7.49367 7.46688 7.04701 7.26688L3.11367 5.52021C1.98034 5.02021 1.98034 4.19354 3.11367 3.69354L7.04701 1.94688C7.49367 1.74687 8.22701 1.74687 8.67367 1.94688Z"
      stroke="#9B8FA1"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M2 7.33301C2 7.89301 2.42 8.53967 2.93333 8.76634L7.46 10.7797C7.80667 10.933 8.2 10.933 8.54 10.7797L13.0667 8.76634C13.58 8.53967 14 7.89301 14 7.33301"
      stroke="#9B8FA1"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M2 10.667C2 11.287 2.36667 11.847 2.93333 12.1003L7.46 14.1137C7.80667 14.267 8.2 14.267 8.54 14.1137L13.0667 12.1003C13.6333 11.847 14 11.287 14 10.667"
      stroke="#9B8FA1"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
