'use client';

import { GOOGLE_TAGS } from '@/configs/global-variables';
import Script from 'next/script';

const GoogleTagManager = () => {
  return (
    <>
      <Script
        strategy="afterInteractive"
        async
        src={`https://www.googletagmanager.com/gtag/js?id=${GOOGLE_TAGS}`}
      />
      <Script
        id="google-tag-manager-init"
        strategy="afterInteractive"
        dangerouslySetInnerHTML={{
          __html: `
                    window.dataLayer = window.dataLayer || [];
                    function gtag(){dataLayer.push(arguments);}
                    gtag('js', new Date());
                    gtag('config', '${GOOGLE_TAGS}');
                    `,
        }}
      />
    </>
  );
};

export default GoogleTagManager;
