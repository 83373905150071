export const WALLET_ENDPOINTS = {
  serviceName: 'apiMicroServiceWallet',
  paths: {},
  createEventPaymentLink: '/SterlingFusion/CreateEventPaymentLink',
  unathenticatedCreateEventPaymentLink: '/SterlingFusion/CreateEventPaymentLinkForUnathorizedUsers',
  verifyEventPaymentWithBankTransfer: '/SterlingFusion/VerifyPayment',
  eventPaymentWithWallet: '/SterlingFusion/EventPayment',
  verifyNIN: '/SterlingFusion/NINVerification',
  verifyBVN: '/SterlingFusion/ValidateBVN',
  validateManualNIN: '/SterlingFusion/NinManualVerification',
  validateNINOTP: '/SterlingFusion/OTPValidation',
  createWalletPin: '/SterlingFusion/WalletPin',
  createTransactionPin: '/SterlingFusion/TransactionPin',
  validateOtp: '/SterlingFusion/OTPValidation',
  validateBVNOTP: '/SterlingFusion/BvnOtpValidation',
  getWalletDetails: '/SterlingFusion/GetWalletDetailsRevamp',
};
