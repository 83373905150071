import { useQuery } from '@tanstack/react-query';
import { cache } from 'react';
import { SHOP_ENDPOINTS } from '@/constants/endpoints/shop';
import { axiosClientInstance } from '@/configs/axiosClientInstance';
import handleError, { ErrorPayload } from '@/utils/handleError';
import { AxiosError } from 'axios';
import { FUSION_API } from '@/configs/global-variables';

export const getEventItemBySlug = cache(async function (params: { slug?: string }) {
  try {
    const res = await axiosClientInstance.get(SHOP_ENDPOINTS.getEventBySlug, {
      params: {
        ...params,
      },
      baseURL: FUSION_API.communitiesApi,
    });
    return res.data?.data;
  } catch (error: unknown) {
    return handleError(error as AxiosError<ErrorPayload>);
  }
});

const useGetEventItemBySlug = ({ Slug }: { Slug?: string }) => {
  return useQuery({
    queryKey: ['get-event-item-by-slug', { Slug }],
    queryFn: () => getEventItemBySlug({ slug: Slug }),
    enabled: !!Slug,
  });
};

export default useGetEventItemBySlug;
