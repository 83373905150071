import { axiosClientInstance } from '@/configs/axiosClientInstance';
import { FUSION_API } from '@/configs/global-variables';
import { WALLET_ENDPOINTS } from '@/constants/endpoints/wallet';
import { ValidateIDFormModel } from '@/lib/models';
import { useMutation } from '@tanstack/react-query';

const mutationFn = async (body: Omit<ValidateIDFormModel, 'idType'>) => {
  try {
    const payload = { ...body, dateOfBirth: new Date(body.dateOfBirth).toISOString() };
    const res = await axiosClientInstance.post(
      WALLET_ENDPOINTS.verifyBVN,
      { ...payload },
      {
        baseURL: FUSION_API.apiMicroServiceWallet,
      }
    );
    return res.data?.data;
  } catch (err: any) {
    return err;
  }
};

const useVerifyBVNMutation = () => {
  return useMutation({
    mutationFn,
  });
};

export default useVerifyBVNMutation;
