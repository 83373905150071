'use client';
import React, { useRef } from 'react';
import { Label } from '../atom/label';
import { Input } from '../atom/input';
import { Controller, useForm } from 'react-hook-form';
import { GenderOptions } from '@/constants';
import {
  Select,
  SelectContent,
  SelectGroup,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from '@/components/atom/select-input';
import RadioButton from '../atom/radio-button';
import { getDaysOfMonth, getMonths, getYearsArray } from '@/lib/days';
import { ManualIDValidationModel } from '@/lib/models';
import { manualIDValidationSchema } from '@/lib/schemas';
import { yupResolver } from '@hookform/resolvers/yup';
import { Button } from '../atom/button';
import dayjs from 'dayjs';
import Validating from './Validating';
import { cn } from '@/lib/utils';
import { usePathname, useRouter, useSearchParams } from 'next/navigation';
import TakeAPicture from './TakeAPicture';
import PictureViewer from './PictureViewer';
import ModalNew from '../atom/modal-new';
import UploadNIN from './UploadNIN';
import useValidateManualNINMutation from '@/hooks/react-query/wallet/mutations/useValidateManualNINMutation';
import { useToast } from '@/hooks/use-toast';

const ManualValidateIdForm = () => {
  const { toast } = useToast();
  const resetButtonRef = useRef<HTMLButtonElement | null>(null);
  const searchParams = useSearchParams();
  const router = useRouter();
  const pathname = usePathname();
  const opened = searchParams.get('wallet-activation') === 'validate-manual-nin';
  const [showPictureTaker, setShowPictureTaker] = React.useState(false);
  const [capturedImg, setCapturedImage] = React.useState('');
  const [ninBase64StringImage, setNinBase64StringImage] = React.useState('');
  const [dates, setDates] = React.useState<{
    month?: string;
    day?: string;
    year?: string;
    initial?: boolean;
  }>({});
  const validateNINMutation = useValidateManualNINMutation();
  const form = useForm<ManualIDValidationModel>({
    resolver: yupResolver(manualIDValidationSchema),
    defaultValues: {
      dateOfBirth: undefined,
      gender: '',
      nin: '',
      firstName: '',
      lastName: '',
    },
    mode: 'onChange',
  });

  const handleDateChange = (value: any, label: 'month' | 'day' | 'year') => {
    if (value) {
      setDates((prev) => ({ ...prev, [label]: value }));
    }
  };
  React.useEffect(() => {
    // Check if all fields (month, day, year) have values
    if (dates?.month && dates?.day && dates?.year && !dates.initial) {
      // Create a new Date object
      const selectedDate: Date = new Date(`${dates.month} ${dates.day}, ${dates.year}`);

      // Check if the date is valid
      form.setValue('dateOfBirth', dayjs(selectedDate).format('YYYY-MM-DD') as unknown as Date, {
        shouldValidate: true,
      });
    }
  }, [dates, form]);

  const onSubmit = (values: ManualIDValidationModel) => {
    const userBase64StringImage = capturedImg.split(',').pop() ?? '';
    const ninImg = ninBase64StringImage.split(',').pop() ?? '';
    validateNINMutation.mutate(
      { ...values, ninBase64StringImage: ninImg, userBase64StringImage },
      {
        onError(e) {
          toast({
            title: 'Error!',
            description: 'Unable to verify NIN at this point. Please try again or contact support',
          });
        },
        onSuccess(res) {
          router.push(`${pathname}?wallet-activation=manual-details-submitted`);
        },
      }
    );
  };

  return (
    <ModalNew
      closeOnOutsideClick={false}
      title=""
      isOpen={opened}
      onClose={() => router.replace(pathname)}
    >
      <div
        className={cn(
          'w-full min-h-[318px]  mx-auto relative',
          showPictureTaker ? 'max-w-[380px]' : 'max-w-[320px]'
        )}
      >
        <div
          className={cn(
            'absolute top-0 bottom-0 left-0 right-0 bg-white mt-6',
            !validateNINMutation.isPending ? 'hidden' : 'block'
          )}
        >
          <Validating text={`Submitting ${validateNINMutation.isPending ? 'NIN details' : ''}`} />
        </div>
        <div className={cn('space-y-4 pb-10', validateNINMutation.isPending ? 'hidden' : 'block')}>
          <h1 className="text-purple-brand3 font-medium text-2xl">Validate your NIN manually</h1>

          <form onSubmit={form.handleSubmit(onSubmit)} className="space-y-4">
            <div className="grid grid-cols-2 items-center justify-between gap-4 w-full">
              <div className="w-full">
                <PictureViewer src={capturedImg} />
              </div>
              <div className="w-full">
                <Button
                  type="button"
                  onClick={() => {
                    setCapturedImage('');
                    setShowPictureTaker(true);
                  }}
                  variant="outline"
                  className="text-purple-brand"
                >
                  {capturedImg ? 'Change picture' : 'Take a picture'}
                </Button>
              </div>
            </div>
            <div className="grid grid-cols-2 gap-4 w-full">
              <div className="w-full">
                <Label htmlFor="firstName">First name</Label>
                <Controller
                  control={form.control}
                  name="firstName"
                  render={({ field, fieldState: { error } }) => (
                    <Input
                      value={field.value}
                      onChange={field.onChange}
                      errorMessage={error?.message}
                    />
                  )}
                />
              </div>
              <div className="w-full">
                <Label htmlFor="lastName">Last name</Label>
                <Controller
                  control={form.control}
                  name="lastName"
                  render={({ field, fieldState: { error } }) => (
                    <Input
                      value={field.value}
                      onChange={field.onChange}
                      errorMessage={error?.message}
                    />
                  )}
                />
              </div>
            </div>

            <div className="w-full">
              <label htmlFor="gender">Gender</label>
              <div className="grid grid-cols-2 gap-4">
                <Controller
                  name="gender"
                  control={form.control}
                  render={({ field, fieldState: { error, isDirty } }) => (
                    <>
                      {GenderOptions.slice(0, 2).map((v) => (
                        <RadioButton
                          key={v.value}
                          id={v.label}
                          value={v.value}
                          label={v.label}
                          name={field.name}
                          onChange={field.onChange}
                          error={isDirty ? error?.message : ''}
                          checked={v.value.toString() === field.value}
                        />
                      ))}
                    </>
                  )}
                />
              </div>
            </div>

            <div className="w-full">
              <label htmlFor="dateOfBirth">Date of birth</label>

              <div className="grid grid-cols-7 gap-4">
                <div className="col-span-3">
                  <Select
                    onValueChange={(v) => handleDateChange(v || dates.month, 'month')}
                    value={dates.month}
                    // defaultValue={dates.month}
                  >
                    <SelectTrigger hasError={!!form.formState.errors?.dateOfBirth?.message}>
                      <SelectValue />
                    </SelectTrigger>
                    <SelectContent>
                      <SelectGroup>
                        {getMonths().map((month) => (
                          <SelectItem key={month} value={month}>
                            {month}
                          </SelectItem>
                        ))}
                      </SelectGroup>
                    </SelectContent>
                  </Select>
                </div>
                <div className="col-span-2">
                  <Select onValueChange={(v) => handleDateChange(v, 'day')} value={dates.day}>
                    <SelectTrigger hasError={!!form.formState.errors?.dateOfBirth?.message}>
                      <SelectValue placeholder="" />
                    </SelectTrigger>
                    <SelectContent>
                      <SelectGroup>
                        {getDaysOfMonth().map((day: number) => (
                          <SelectItem key={day} value={`${day}`}>
                            {day}
                          </SelectItem>
                        ))}
                      </SelectGroup>
                    </SelectContent>
                  </Select>
                </div>

                <div className="col-span-2">
                  <Select onValueChange={(v) => handleDateChange(v, 'year')} value={dates.year}>
                    <SelectTrigger hasError={!!form.formState.errors?.dateOfBirth?.message}>
                      <SelectValue placeholder="" />
                    </SelectTrigger>
                    <SelectContent>
                      <SelectGroup>
                        {getYearsArray().map((year: number) => (
                          <SelectItem key={year} value={`${year}`}>
                            {year}
                          </SelectItem>
                        ))}
                      </SelectGroup>
                    </SelectContent>
                  </Select>
                </div>
              </div>

              {form.formState.errors?.dateOfBirth?.message && (
                <p className="text-sm text-opal-brand transition duration-500 ease-in-out">
                  {form.formState.errors?.dateOfBirth?.message}
                </p>
              )}
            </div>

            <div className="w-full">
              <Label htmlFor="nin">National Identification Number</Label>
              <Controller
                control={form.control}
                name="nin"
                render={({ field, fieldState: { error } }) => (
                  <Input
                    value={field.value}
                    onChange={field.onChange}
                    errorMessage={error?.message}
                    placeholder="Enter your National Identification Number"
                  />
                )}
              />
            </div>

            <UploadNIN
              ref={resetButtonRef}
              onFileSelected={(v) => {
                setNinBase64StringImage(v.base64);
              }}
            />

            <div className="flex items-center gap-4 pt-6 space-y-4">
              <Button
                className="w-full"
                variant="outline"
                type="button"
                onClick={() => {
                  form.reset(
                    {},
                    {
                      keepErrors: false,
                      keepDirty: false,
                      keepIsSubmitted: false,
                      keepTouched: false,
                      keepIsValid: false,
                      keepSubmitCount: false,
                    }
                  );
                  setDates({});
                  setCapturedImage('');
                  if (resetButtonRef.current) {
                    resetButtonRef.current.click();
                  }
                }}
              >
                Clear Form
              </Button>
              <Button
                className="w-full !mt-0"
                type="submit"
                disabled={!form.formState.isValid || !capturedImg || !ninBase64StringImage}
                loading={validateNINMutation.isPending}
              >
                Submit Details
              </Button>
            </div>
          </form>
        </div>

        {showPictureTaker && (
          <TakeAPicture
            onCapture={(imgSrc) => {
              setCapturedImage(imgSrc);
              setShowPictureTaker(false);
            }}
          />
        )}
      </div>
    </ModalNew>
  );
};

export default ManualValidateIdForm;
