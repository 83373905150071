export const VideoPlay = () => (
  <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M18.3332 12.5003V7.50033C18.3332 3.33366 16.6665 1.66699 12.4998 1.66699H7.49984C3.33317 1.66699 1.6665 3.33366 1.6665 7.50033V12.5003C1.6665 16.667 3.33317 18.3337 7.49984 18.3337H12.4998C16.6665 18.3337 18.3332 16.667 18.3332 12.5003Z"
      stroke="#685B6E"
      strokeWidth="1.25"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M2.1001 5.92578H17.9001"
      stroke="#685B6E"
      strokeWidth="1.25"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M7.1001 1.75879V5.80879"
      stroke="#685B6E"
      strokeWidth="1.25"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M12.8999 1.75879V5.43379"
      stroke="#685B6E"
      strokeWidth="1.25"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M8.125 12.0421V11.0421C8.125 9.75875 9.03333 9.23376 10.1417 9.87542L11.0083 10.3754L11.875 10.8754C12.9833 11.5171 12.9833 12.5671 11.875 13.2088L11.0083 13.7088L10.1417 14.2088C9.03333 14.8504 8.125 14.3254 8.125 13.0421V12.0421V12.0421Z"
      stroke="#685B6E"
      strokeWidth="1.25"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
