import { axiosClientInstance } from '@/configs/axiosClientInstance';
import { FUSION_API } from '@/configs/global-variables';
import { SHOP_ENDPOINTS } from '@/constants/endpoints/shop';
import { CreateEventPayloadType } from '@/types';
import { useMutation } from '@tanstack/react-query';

export async function createEventAPI(body: CreateEventPayloadType) {
  const res = await axiosClientInstance.post(
    SHOP_ENDPOINTS.createEvent,
    {
      ...body,
      physicalLocation: {
        address: body.physicalLocation,
        state: '',
        country: 'Nigeria',
      },
    },
    {
      baseURL: FUSION_API.communitiesApi,
    }
  );
  return res.data?.data;
}

const useCreateEventMutation = (isReset = false) => {
  return useMutation({
    mutationKey: ['CreateEventMutation', { reset: isReset }],
    mutationFn: (payload: CreateEventPayloadType) => createEventAPI(payload),
  });
};

export default useCreateEventMutation;
