const Warning = ({ filled }: { filled: boolean }) =>
  filled ? (
    <svg
      width="120"
      height="120"
      viewBox="0 0 120 120"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        opacity="0.4"
        d="M105.401 42.9002V77.1C105.401 82.7 102.401 87.9002 97.551 90.7502L67.851 107.9C63.001 110.7 57.0008 110.7 52.1008 107.9L22.4008 90.7502C17.5508 87.9502 14.5508 82.75 14.5508 77.1V42.9002C14.5508 37.3002 17.5508 32.1 22.4008 29.25L52.1008 12.1C56.9508 9.3 62.951 9.3 67.851 12.1L97.551 29.25C102.401 32.1 105.401 37.2502 105.401 42.9002Z"
        fill="#FFAA37"
      />
      <path
        d="M60 68.75C57.95 68.75 56.25 67.05 56.25 65V38.75C56.25 36.7 57.95 35 60 35C62.05 35 63.75 36.7 63.75 38.75V65C63.75 67.05 62.05 68.75 60 68.75Z"
        fill="#7F3A0F"
      />
      <path
        d="M60 86.2483C59.35 86.2483 58.7 86.0982 58.1 85.8482C57.45 85.5982 56.9499 85.2484 56.4499 84.7984C55.9999 84.2984 55.65 83.7483 55.35 83.1483C55.1 82.5483 55 81.8983 55 81.2483C55 79.9483 55.4999 78.6482 56.4499 77.6982C56.9499 77.2482 57.45 76.8984 58.1 76.6484C59.95 75.8484 62.1501 76.2982 63.5501 77.6982C64.0001 78.1982 64.3499 78.6983 64.5999 79.3483C64.8499 79.9483 65 80.5983 65 81.2483C65 81.8983 64.8499 82.5483 64.5999 83.1483C64.3499 83.7483 64.0001 84.2984 63.5501 84.7984C62.6001 85.7484 61.35 86.2483 60 86.2483Z"
        fill="#7F3A0F"
      />
    </svg>
  ) : (
    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M8 9.1665C7.72667 9.1665 7.5 8.93984 7.5 8.6665V5.1665C7.5 4.89317 7.72667 4.6665 8 4.6665C8.27333 4.6665 8.5 4.89317 8.5 5.1665V8.6665C8.5 8.93984 8.27333 9.1665 8 9.1665Z"
        fill="#7F7186"
      />
      <path
        d="M8.0026 11.4998C7.8226 11.4998 7.65592 11.4332 7.52926 11.3065C7.46926 11.2398 7.42261 11.1665 7.38261 11.0865C7.34928 11.0065 7.33594 10.9198 7.33594 10.8332C7.33594 10.6598 7.40926 10.4865 7.52926 10.3598C7.77592 10.1131 8.22929 10.1131 8.47595 10.3598C8.59595 10.4865 8.66927 10.6598 8.66927 10.8332C8.66927 10.9198 8.64926 11.0065 8.61593 11.0865C8.58259 11.1665 8.53595 11.2398 8.47595 11.3065C8.34929 11.4332 8.1826 11.4998 8.0026 11.4998Z"
        fill="#7F7186"
      />
      <path
        d="M7.99752 15.1667C7.55085 15.1667 7.09751 15.0534 6.69751 14.8201L2.73751 12.5334C1.93751 12.0667 1.4375 11.2067 1.4375 10.2801V5.72008C1.4375 4.79341 1.93751 3.93341 2.73751 3.46674L6.69751 1.18008C7.49751 0.713411 8.49086 0.713411 9.29753 1.18008L13.2575 3.46674C14.0575 3.93341 14.5575 4.79341 14.5575 5.72008V10.2801C14.5575 11.2067 14.0575 12.0667 13.2575 12.5334L9.29753 14.8201C8.89753 15.0534 8.44418 15.1667 7.99752 15.1667ZM7.99752 1.8334C7.72418 1.8334 7.44418 1.90674 7.19751 2.04674L3.23751 4.3334C2.74417 4.62006 2.4375 5.14674 2.4375 5.72008V10.2801C2.4375 10.8467 2.74417 11.3801 3.23751 11.6667L7.19751 13.9534C7.69084 14.2401 8.30419 14.2401 8.79085 13.9534L12.7509 11.6667C13.2442 11.3801 13.5509 10.8534 13.5509 10.2801V5.72008C13.5509 5.15341 13.2442 4.62006 12.7509 4.3334L8.79085 2.04674C8.55085 1.90674 8.27085 1.8334 7.99752 1.8334Z"
        fill="#7F7186"
      />
    </svg>
  );

export default Warning;
