'use client';

import GoogleAnalytics from './GoogleAnalytics';
import GoogleTagManager from './GoogleTagManager';
import MicrosoftClarity from './MicrosoftClarity';

const Metrics = () => {
  return (
    <>
      <MicrosoftClarity />
      <GoogleTagManager />
      <GoogleAnalytics />
    </>
  );
};

export default Metrics;
