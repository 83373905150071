'use client';
import React, { useEffect } from 'react';
// import { MotionLazy } from './animate/motion-lazy';
import { AppProgressBar as ProgressBar } from 'next-nprogress-bar';
import { SessionProvider } from 'next-auth/react';
import { QueryClientProvider } from '@tanstack/react-query';
import { TooltipProvider } from './atom/tooltip';
import { MixpanelProvider } from '@/providers/MixPanelProvider';
import { getQueryClient } from '@/app/getQueryClient';
import { FirebaseProvider } from '@/providers/FirebaseProvider';
import Clarity from '@microsoft/clarity';
import { MICROSOFT_CLARITY } from '@/configs/global-variables';

const Providers = ({ children }: { children: React.ReactNode }) => {
  const queryClient = getQueryClient();
  useEffect(() => {
    Clarity.init(MICROSOFT_CLARITY);
  }, []);
  return (
    <SessionProvider>
      <QueryClientProvider client={queryClient}>
        <FirebaseProvider>
          <MixpanelProvider>
            <ProgressBar
              height="4px"
              color="#7635dc"
              options={{ showSpinner: false }}
              shallowRouting
            />
            <TooltipProvider>{children}</TooltipProvider>
          </MixpanelProvider>
        </FirebaseProvider>
      </QueryClientProvider>
    </SessionProvider>
  );
};

export default Providers;
