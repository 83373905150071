export const EditIcon = () => (
  <svg width="64" height="64" viewBox="0 0 64 64" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      opacity="0.4"
      d="M41.28 8H20.0534C10.8534 8 5.33337 13.4933 5.33337 22.72V43.92C5.33337 53.1733 10.8534 58.6667 20.0534 58.6667H41.2534C50.48 58.6667 55.9734 53.1733 55.9734 43.9467V22.72C56 13.4933 50.48 8 41.28 8Z"
      fill="#601E8A"
    />
    <path
      d="M56.0533 7.94577C51.28 3.14577 46.6133 3.03911 41.7066 7.94577L38.6933 10.9324C38.4266 11.1991 38.3466 11.5724 38.4533 11.9191C40.32 18.4524 45.5466 23.6791 52.08 25.5458C52.16 25.5724 52.2933 25.5724 52.3733 25.5724C52.64 25.5724 52.9066 25.4658 53.0933 25.2791L56.0533 22.2924C58.48 19.8658 59.68 17.5458 59.68 15.1724C59.68 12.7724 58.48 10.3991 56.0533 7.94577Z"
      fill="#FF951C"
    />
    <path
      d="M47.6267 27.7859C46.9067 27.4393 46.2133 27.0926 45.5733 26.6926C45.04 26.3726 44.5066 26.0259 44 25.6526C43.5733 25.3859 43.0933 24.9859 42.6133 24.5859C42.56 24.5593 42.4 24.4259 42.1866 24.2126C41.36 23.5459 40.48 22.6393 39.6533 21.6526C39.6 21.5993 39.44 21.4393 39.3066 21.1993C39.04 20.9059 38.64 20.3993 38.2933 19.8393C38 19.4659 37.6533 18.9326 37.3333 18.3726C36.9333 17.7059 36.5867 17.0393 36.2667 16.3459C35.92 15.5993 35.6533 14.9059 35.4133 14.2393L21.0667 28.5859C20.1333 29.5193 19.2266 31.2793 19.04 32.5859L17.8933 40.5326C17.6533 42.2126 18.1066 43.7859 19.1466 44.8259C20.0266 45.7059 21.2266 46.1593 22.56 46.1593C22.8533 46.1593 23.1466 46.1326 23.44 46.1059L31.36 44.9859C32.6667 44.7993 34.4267 43.9193 35.36 42.9593L49.7066 28.6126C49.04 28.3993 48.3733 28.1059 47.6267 27.7859Z"
      fill="#601E8A"
    />
  </svg>
);
