import React, { useCallback, useEffect, useRef, useState } from 'react';
import Cropper, { Area } from 'react-easy-crop';
import Webcam from 'react-webcam';
import { Button } from '../atom/button';
import Progressing from '../atom/Progressing';
import { cn } from '@/lib/utils';

const TakeAPicture = ({
  onCapture,
  loading,
  className,
  cameraOnly,
}: {
  onCapture: (v: string) => void;
  loading?: boolean;
  className?: string;
  cameraOnly?: boolean;
}) => {
  const webcamRef = useRef<Webcam>(null);
  const [capturedImage, setCapturedImage] = useState<string | null>(null);
  const [error, setError] = useState<string | null>(null);

  const capture = useCallback(() => {
    if (webcamRef.current) {
      const imageSrc = webcamRef.current.getScreenshot();
      if (imageSrc) {
        onCapture(imageSrc);
      }
    }
  }, []);

  const onCropChange = (crop: any) => {
    console.log({ crop });
  };

  const onCropComplete = (croppedArea: any, croppedAreaPixels: any) => {
    console.log(croppedAreaPixels.width / croppedAreaPixels.height);
  };

  const handleUserMediaError = useCallback((error: string | DOMException) => {
    console.error('Webcam Error:', error);
    setError(`Camera error: ${error.toString()}`);
  }, []);

  return (
    <div className={cn('space-y-4 absolute top-0 bottom-0 left-0 right-0 bg-white', className)}>
      {/* Header */}
      {!cameraOnly && (
        <div>
          <h1 className="text-purple-brand3 font-medium  text-2xl">Take a picture</h1>

          <p className="text-base text-gray-brand7 ">
            Place your face in the middle of the circle and click the capture button
          </p>
        </div>
      )}

      {/* Camera View */}
      <div className="relative aspect-square w-full overflow-hidden rounded-lg h-[380px] bg-gray-100">
        {loading && (
          <div className="absolute top-0 left-0 right-0 bottom-0 flex items-center justify-center z-50 bg-white">
            <Progressing />
          </div>
        )}
        {capturedImage ? (
          <Cropper
            image={capturedImage}
            crop={{ x: 0, y: 0 }}
            aspect={1}
            cropShape="round"
            showGrid={false}
            onCropChange={onCropChange}
            onCropComplete={onCropComplete}
          />
        ) : (
          <>
            <Webcam
              audio={false}
              ref={webcamRef}
              screenshotFormat="image/png"
              videoConstraints={{ facingMode: 'user' }}
              className="absolute left-0 top-0 w-full h-full object-cover"
              onUserMediaError={handleUserMediaError}
            />
            {/* Overlay with circular cutout */}
            <div className="absolute inset-0 bg-transparent">
              <div
                className="absolute left-1/2 top-1/2 -translate-x-1/2 -translate-y-1/2 w-[80%] aspect-square rounded-full"
                style={{
                  boxShadow: '0 0 0 9999px #F7F7F8',
                  border: '2px solid rgba(255, 255, 255, 0.5)',
                  opacity: 0.5,
                }}
              />
            </div>
          </>
        )}

        {error && <p className="text-destructive">{error.toString()}</p>}
      </div>

      {/* Capture Button */}
      <div className="flex justify-center mt-6">
        <Button onClick={capture} className="w-full max-w-[146px]" loading={loading}>
          <svg
            width="20"
            height="20"
            viewBox="0 0 20 20"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            className="mr-1"
          >
            <path
              d="M14.3667 18.9577H5.6334C3.30006 18.9577 1.81673 17.566 1.6834 15.241L1.25006 8.36602C1.1834 7.32435 1.54173 6.32435 2.2584 5.56602C2.96673 4.80768 3.96673 4.37435 5.00006 4.37435C5.26673 4.37435 5.52506 4.21602 5.65006 3.96602L6.25006 2.77435C6.74173 1.79935 7.97506 1.04102 9.05006 1.04102H10.9584C12.0334 1.04102 13.2584 1.79935 13.7501 2.76602L14.3501 3.98268C14.4751 4.21602 14.7251 4.37435 15.0001 4.37435C16.0334 4.37435 17.0334 4.80768 17.7417 5.56602C18.4584 6.33268 18.8167 7.32435 18.7501 8.36602L18.3167 15.2493C18.1667 17.6077 16.7251 18.9577 14.3667 18.9577ZM9.05006 2.29102C8.4334 2.29102 7.65006 2.77435 7.36673 3.33268L6.76673 4.53268C6.41673 5.20768 5.74173 5.62435 5.00006 5.62435C4.30006 5.62435 3.65006 5.90768 3.16673 6.41602C2.69173 6.92435 2.45006 7.59102 2.50006 8.28268L2.9334 15.166C3.0334 16.8493 3.94173 17.7077 5.6334 17.7077H14.3667C16.0501 17.7077 16.9584 16.8493 17.0667 15.166L17.5001 8.28268C17.5417 7.59102 17.3084 6.92435 16.8334 6.41602C16.3501 5.90768 15.7001 5.62435 15.0001 5.62435C14.2584 5.62435 13.5834 5.20768 13.2334 4.54935L12.6251 3.33268C12.3501 2.78268 11.5667 2.29935 10.9501 2.29935H9.05006V2.29102Z"
              fill="white"
            />
            <path
              d="M11.25 7.29102H8.75C8.40833 7.29102 8.125 7.00768 8.125 6.66602C8.125 6.32435 8.40833 6.04102 8.75 6.04102H11.25C11.5917 6.04102 11.875 6.32435 11.875 6.66602C11.875 7.00768 11.5917 7.29102 11.25 7.29102Z"
              fill="white"
            />
            <path
              d="M10.0003 15.6257C8.15866 15.6257 6.66699 14.134 6.66699 12.2923C6.66699 10.4507 8.15866 8.95898 10.0003 8.95898C11.842 8.95898 13.3337 10.4507 13.3337 12.2923C13.3337 14.134 11.842 15.6257 10.0003 15.6257ZM10.0003 10.209C8.85033 10.209 7.91699 11.1423 7.91699 12.2923C7.91699 13.4423 8.85033 14.3757 10.0003 14.3757C11.1503 14.3757 12.0837 13.4423 12.0837 12.2923C12.0837 11.1423 11.1503 10.209 10.0003 10.209Z"
              fill="white"
            />
          </svg>
          Capture
        </Button>
      </div>
    </div>
  );
};

export default TakeAPicture;
