import { axiosClientInstance } from '@/configs/axiosClientInstance';
import { FUSION_API } from '@/configs/global-variables';
import { SHOP_ENDPOINTS } from '@/constants/endpoints/shop';
import { UpdateEventPayloadType, UpdateEventTitleFormType } from '@/types';
import handleError, { ErrorPayload } from '@/utils/handleError';
import { useMutation } from '@tanstack/react-query';
import { AxiosError } from 'axios';

export async function updateEventTitle(payload: UpdateEventTitleFormType) {
  const values: Partial<UpdateEventPayloadType> = {
    title: payload.eventName,
    description: payload.description,
    cost: payload.price,
    eventId: payload.eventId,
    numberAvailable: payload.numbersAvailable,
  };

  const body = payload.base64
    ? {
        ...values,
        images: [
          {
            base64: payload.base64 ?? '',
            contentType: payload.imageFile?.type,
            name: payload.imageFile?.name,
          },
        ],
      }
    : { ...values };

  try {
    const res = await axiosClientInstance.put(SHOP_ENDPOINTS.updateEvent, body, {
      baseURL: FUSION_API.communitiesApi,
    });
    return res.data?.data;
  } catch (error: unknown) {
    return handleError(error as AxiosError<ErrorPayload>);
  }
}

const useUpdateEventTitleMutation = () => {
  return useMutation({
    mutationKey: ['UpdateEventTitle'],
    mutationFn: (payload: UpdateEventTitleFormType) => updateEventTitle(payload),
  });
};

export default useUpdateEventTitleMutation;
