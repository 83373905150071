const useOptimizeImage = () => {
  const optimizeImageUrl = async (imageUrl: string) => {
    try {
      // Fetch the image from the URL
      const response = await fetch(imageUrl);
      if (!response.ok) throw new Error('Failed to fetch image');

      const blob = await response.blob();
      const image = await createImageBitmap(blob);

      // Create a canvas to draw and convert the image
      const canvas = document.createElement('canvas');
      const ctx = canvas.getContext('2d');

      // Set canvas dimensions to the image dimensions
      canvas.width = image.width;
      canvas.height = image.height;

      // Draw the image on the canvas
      ctx?.drawImage(image, 0, 0);

      // Convert the canvas content to WebP format
      const quality = 0.8; // Set quality (0 to 1)
      const webpBlob = await new Promise((resolve) =>
        canvas.toBlob(
          (blob) => resolve(blob),
          'image/webp', // Specify WebP format
          quality // Set quality (0 to 1)
        )
      );

      // Convert the WebP Blob to a base64 string
      const reader = new FileReader();
      reader.readAsDataURL(webpBlob as Blob);

      reader.onloadend = () => {
        const base64String = reader.result;

        // Extract the image name from the URL
        const imageName = (imageUrl.split('/').pop()?.split('.')[0] ?? 'image') + '.webp';

        // Set the result
        return {
          base64: base64String,
          name: imageName,
          contentType: 'image/webp',
        };
      };
    } catch (err) {
      return {
        base64: '',
        name: '',
        contentType: '',
      };
    }
  };
  const optimizeImageFile = (file: File): Promise<{ name: string; base64: string }> => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.onload = (event) => {
        const img = new Image();
        img.src = (event.target?.result ?? '') as string;

        img.onload = () => {
          const canvas = document.createElement('canvas');
          const ctx = canvas.getContext('2d');

          // Set maximum dimensions for optimization
          const MAX_WIDTH = 800;
          const MAX_HEIGHT = 800;
          let width = img.width;
          let height = img.height;

          // Resize the image if it exceeds the maximum dimensions
          if (width > MAX_WIDTH || height > MAX_HEIGHT) {
            if (width > height) {
              height *= MAX_WIDTH / width;
              width = MAX_WIDTH;
            } else {
              width *= MAX_HEIGHT / height;
              height = MAX_HEIGHT;
            }
          }

          canvas.width = width;
          canvas.height = height;

          // Draw the image onto the canvas
          ctx?.drawImage(img, 0, 0, width, height);

          // Convert the canvas image to WebP format
          canvas.toBlob(
            (blob) => {
              if (!blob) {
                reject(new Error('Failed to convert image to WebP.'));
                return;
              }

              // Convert the WebP blob to base64
              const reader = new FileReader();
              reader.onload = () => {
                resolve({
                  base64: reader.result as string,
                  name: file.name.replace(/\.[^/.]+$/, '.webp'), // Replace extension with .webp
                });
              };
              reader.onerror = () => reject(new Error('Failed to read WebP blob.'));
              reader.readAsDataURL(blob);
            },
            'image/webp',
            0.8 // Quality (0.8 = 80%)
          );

          // Clean up the canvas element
          canvas.width = 0; // Clear the canvas
          canvas.height = 0; // Clear the canvas
          canvas.remove(); // Remove the canvas from the DOM (if it was added)
        };

        img.onerror = () => reject(new Error('Failed to load image.'));
      };

      reader.onerror = () => reject(new Error('Failed to read file.'));
      reader.readAsDataURL(file);
    });
  };

  return { optimizeImageFile, optimizeImageUrl };
};

export default useOptimizeImage;
