import React from 'react';
import { Dialog, DialogContent, DialogFooter, DialogHeader, DialogTitle } from './atom/dialog';
import { Button } from './atom/button';
import { ChevronLeft } from 'lucide-react';
import Webcam from 'react-webcam';
import base64ToFile from '@/lib/base64-to-file';
import useOptimizeImage from '@/hooks/use-optimize-image';
import { cn } from '@/lib/utils';

type WebcamModalProp = {
  onCaptureImage: (str: string | null | undefined, f?: File) => void;
  type: 'landscape' | 'portrait';
  open: boolean;
  onOpenChange: () => void;
};

const aspectRation = {
  landscape: {
    width: 1920,
    height: 1080,
  },
  portrait: {
    height: 1920,
    width: 1080,
  },
};

const WebcamModal = ({ onCaptureImage, type, open, onOpenChange }: WebcamModalProp) => {
  const { optimizeImageFile } = useOptimizeImage();
  const webcamRef = React.useRef<Webcam | null>(null);

  const capture = async () => {
    const imageSrc = webcamRef?.current?.getScreenshot();
    const fileName = `capture-${new Date().getTime()}`;
    const file = base64ToFile(imageSrc as string, fileName);
    const optimized = await optimizeImageFile(file);
    onCaptureImage(optimized.base64, file);
    onOpenChange();
  };

  return (
    <div
      className={cn(
        'absolute top-0 left-0 right-0 bottom-0 bg-white z-50 p-6',
        open ? 'flex items-center justify-center flex-col' : 'hidden'
      )}
    >
      <div className="relative font-medium text-lg md:text-lg text-purple-brand3 items-center flex gap-2">
        <Button
          variant="ghost"
          size="icon"
          className="rounded-full h-fit w-fit md:hidden"
          onClick={onOpenChange}
        >
          <ChevronLeft size={16} />
        </Button>
        <span className="sr-only">Capture Image</span>
        Capture Image
      </div>
      <div className="flex h-full items-center flex-col flex-1 gap-8 w-full mt-6">
        <Webcam
          className="h-full"
          ref={webcamRef}
          screenshotFormat="image/webp"
          screenshotQuality={1}
          videoConstraints={{
            facingMode: 'user',
            ...aspectRation[type],
          }}
        />
      </div>
      <div className="justify-end flex gap-4 w-full">
        <Button variant="outline" size="sm" onClick={onOpenChange}>
          Cancel
        </Button>
        <Button size="sm" onClick={capture}>
          Capture
        </Button>
      </div>
    </div>
  );
};

export default WebcamModal;
