export const ClockIcon = () => (
  <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M18.3332 10.0003C18.3332 14.6003 14.5998 18.3337 9.99984 18.3337C5.39984 18.3337 1.6665 14.6003 1.6665 10.0003C1.6665 5.40033 5.39984 1.66699 9.99984 1.66699C14.5998 1.66699 18.3332 5.40033 18.3332 10.0003Z"
      stroke="#685B6E"
      strokeWidth="1.25"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M13.0914 12.6495L10.5081 11.1078C10.0581 10.8411 9.69141 10.1995 9.69141 9.67448V6.25781"
      stroke="#685B6E"
      strokeWidth="1.25"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
