import { axiosClientInstance } from '@/configs/axiosClientInstance';
import { FUSION_API } from '@/configs/global-variables';
import { SHOP_ENDPOINTS } from '@/constants/endpoints/shop';
import { UpdateEventDetailFormType } from '@/types';
import { ChannelEnum } from '@/utils/enums';
import handleError, { ErrorPayload } from '@/utils/handleError';
import { useMutation } from '@tanstack/react-query';
import { AxiosError } from 'axios';

export async function updateEventDetail(payload: UpdateEventDetailFormType) {
  try {
    const res = await axiosClientInstance.put(
      SHOP_ENDPOINTS.updateEvent,
      { ...payload, channelId: ChannelEnum.Web },
      {
        baseURL: FUSION_API.communitiesApi,
      }
    );
    return res.data?.data;
  } catch (error: unknown) {
    return handleError(error as AxiosError<ErrorPayload>);
  }
}

const useUpdateEventDetailMutation = () => {
  return useMutation({
    mutationKey: ['UpdateEventDetail'],
    mutationFn: (payload: UpdateEventDetailFormType) => updateEventDetail(payload),
  });
};

export default useUpdateEventDetailMutation;
