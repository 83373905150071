'use client';

import React from 'react';
import { Dialog, DialogContent, DialogHeader, DialogTitle } from '@/components/atom/dialog';
import { useRouter, useSearchParams } from 'next/navigation';
import PostEditor from './PostEditor';
import { Button } from '@/components/atom/button';
import { ChevronLeft } from 'lucide-react';

const CreatePostModal = () => {
  const router = useRouter();
  const params = useSearchParams();
  return (
    <Dialog open={params.get('type') === 'post'} onOpenChange={() => router.back()}>
      <DialogContent className="w-screen-safe h-screen-safe md:h-fit p-4 md:p-10 antialiased flex flex-col items-center  md:w-full max-w-[744px] rounded-none md:rounded-xl">
        <DialogHeader className="w-full mx-auto text-start">
          <DialogTitle className="relative font-medium text-2xl text-purple-brand3 flex items-center">
            <Button
              variant="ghost"
              size="icon"
              className="rounded-full h-fit w-fit md:hidden"
              onClick={() => router.back()}
            >
              <ChevronLeft size={16} />
            </Button>
            <span className="sr-only">Create a post</span>
            <span className="block md:hidden text-sm ml-4">Go back</span>
            <span className="hidden md:block">Create a post</span>
          </DialogTitle>
        </DialogHeader>
        <div className="flex items-center justify-center gap-8 w-full h-full mb-2 md:mb-10 mt-6">
          <PostEditor className="!min-h-[calc(100vh-350px)] md:!min-h-[152px]" />
        </div>
      </DialogContent>
    </Dialog>
  );
};

export default CreatePostModal;
