export const EmojiHappy = () => (
  <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M7.49984 18.3337H12.4998C16.6665 18.3337 18.3332 16.667 18.3332 12.5003V7.50033C18.3332 3.33366 16.6665 1.66699 12.4998 1.66699H7.49984C3.33317 1.66699 1.6665 3.33366 1.6665 7.50033V12.5003C1.6665 16.667 3.33317 18.3337 7.49984 18.3337Z"
      stroke="#685B6E"
      strokeWidth="1.25"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M12.9165 8.125C13.6069 8.125 14.1665 7.56536 14.1665 6.875C14.1665 6.18464 13.6069 5.625 12.9165 5.625C12.2261 5.625 11.6665 6.18464 11.6665 6.875C11.6665 7.56536 12.2261 8.125 12.9165 8.125Z"
      stroke="#685B6E"
      strokeWidth="1.25"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M7.0835 8.125C7.77385 8.125 8.3335 7.56536 8.3335 6.875C8.3335 6.18464 7.77385 5.625 7.0835 5.625C6.39314 5.625 5.8335 6.18464 5.8335 6.875C5.8335 7.56536 6.39314 8.125 7.0835 8.125Z"
      stroke="#685B6E"
      strokeWidth="1.25"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M7 11.083H13C13.4167 11.083 13.75 11.4163 13.75 11.833C13.75 13.908 12.075 15.583 10 15.583C7.925 15.583 6.25 13.908 6.25 11.833C6.25 11.4163 6.58333 11.083 7 11.083Z"
      stroke="#685B6E"
      strokeWidth="1.25"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
